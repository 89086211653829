<template>
  <b-form @submit="OnSubmit" v-if="clientToUpdate">
    <b-card no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <b class="text-secondary">Configurações</b>
          <div class="d-flex flex-row-reverse">
            <b-button
              v-if="$can('client_config:edit')"
              type="submit"
              variant="primary"
              >Salvar</b-button
            >

            <router-link
              :to="{
                name: 'clients_show'
              }"
              class="btn btn-outline-muted mr-2"
              >Cancelar</router-link
            >
          </div>
        </div>
      </template>

      <b-tabs pills card vertical class="edit-menu-sidebar">
        <b-tab active>
          <template #title>
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-warning"
              v-if="!validations.dados_basicos"
            />
            Dados Básicos
          </template>
          <DadosBasicos ref="dados_basicos" />
        </b-tab>
        <b-tab>
          <template #title>
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-warning"
              v-if="!validations.usuarios"
            />
            Usuários
          </template>
          <Usuarios ref="usuarios" />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-form>
</template>

<script>
import DadosBasicos from "./Forms/DadosBasicos.vue";
import Usuarios from "./Forms/Usuarios.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { DadosBasicos, Usuarios },

  props: {},
  data: () => ({
    validations: {
      dados_basicos: true,
      usuarios: true
    }
  }),

  computed: {
    ...mapState("clients", ["clientToUpdate"])
  },

  methods: {
    ...mapActions("clients", ["ActionUpdate"]),

    isValid() {
      this.validations.dados_basicos = this.$refs.dados_basicos.isValid();
      // this.validations.usuarios = this.$refs.usuarios.isValid();
      return Object.values(this.validations).every(Boolean);
    },

    async OnSubmit(event) {
      event.preventDefault();

      if (!this.isValid()) {
        this.$notifications.addNotification({
          message: "Os dados do formulário são inválidos",
          type: "danger"
        });
      } else {
        try {
          const change_form_data = (formData, values, index = null) => {
            for (var key in values) {
              let value = values[key];

              if (index) {
                key = index + "[" + key + "]";
              }

              if (value instanceof File) {
                formData.append(key, value);
              } else if (typeof value === "object") {
                change_form_data(formData, value, key);
              } else {
                formData.append(key, value);
              }
            }
          };

          const formData = new FormData();
          change_form_data(formData, this.clientToUpdate);
          await this.ActionUpdate(formData);

          this.$notifications.addNotification({
            message: "Atualizado com sucesso!",
            type: "success"
          });
        } catch (error) {
          console.log(error);
          this.$notifications.addNotification({
            message: "Os dados do formulário são inválidos",
            type: "danger"
          });
        }
      }
    }
  }
};
</script>

<style></style>
