<template>
  <div class="card border-0 radius-bottom-0" v-if="client">
    <div class="card-header bg-dark text-white row m-0 pt-4 pb-4">
      <div class="col-12">
        <div class="row">
          <div class="col-11">
            <h4 class="card-tite d-flex align-items-center">
              <span>
                {{ client.name }}
              </span>
            </h4>
            <div class="mt-4">
              <p class="text-muted" v-if="client.description">
                {{ client.description }}
              </p>
            </div>
            <div>
              <label class="form-label">Urls</label>
              <p
                v-for="(url, key) in client.urls"
                :key="key"
                class="text-muted"
              >
                {{ url }}
              </p>
            </div>
          </div>

          <div class="col-1 d-flex align-items-end">
            <div class="pr-0 d-flex align-items-end flex-column">
              <div class="mt-auto">
                <router-link
                  v-if="$can('client_config:edit')"
                  :to="{
                    name: 'clients_edit',
                    params: { id: client.id }
                  }"
                  class="btn btn-outline-primary link-edit"
                  >Editar</router-link
                >

                <router-link
                  v-else
                  :to="{
                    name: 'clients_show',
                    params: { id: client.id }
                  }"
                  class="btn btn-outline-primary link-edit"
                  >Voltar</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    tabs: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState("clients", ["client"])
  },
  methods: {},
  async created() {}
};
</script>

<style lang="scss"></style>
