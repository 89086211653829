<template>
  <div class="container col-12">
    <div class="d-flex flex-row-reverse mb-2">
      <b-button size="sm" variant="info" :to="{ name: 'users_list' }"
        >Gerenciar Usuários</b-button
      >
    </div>
    <b-table striped hover :items="client.users" :fields="fields"></b-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => {
    return {};
  },
  computed: {
    ...mapState("clients", ["client"]),
    fields() {
      return [
        { key: "id", label: "Id" },
        { key: "name", label: "Nome" },
        { key: "email", label: "Email" },
      ];
    },
  },
  methods: {
    ...mapActions("clients", ["ActionShow"]),
  },
  async created() {
    await this.ActionShow();
  },
};
</script>

<style>
</style>
