var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.client)?_c('div',{staticClass:"card border-0 radius-bottom-0"},[_c('div',{staticClass:"card-header bg-dark text-white row m-0 pt-4 pb-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11"},[_c('h4',{staticClass:"card-tite d-flex align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.client.name)+" ")])]),_c('div',{staticClass:"mt-4"},[(_vm.client.description)?_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.client.description)+" ")]):_vm._e()]),_c('div',[_c('label',{staticClass:"form-label"},[_vm._v("Urls")]),_vm._l((_vm.client.urls),function(url,key){return _c('p',{key:key,staticClass:"text-muted"},[_vm._v(" "+_vm._s(url)+" ")])})],2)]),_c('div',{staticClass:"col-1 d-flex align-items-end"},[_c('div',{staticClass:"pr-0 d-flex align-items-end flex-column"},[_c('div',{staticClass:"mt-auto"},[(_vm.$can('client_config:edit'))?_c('router-link',{staticClass:"btn btn-outline-primary link-edit",attrs:{"to":{
                  name: 'clients_edit',
                  params: { id: _vm.client.id }
                }}},[_vm._v("Editar")]):_c('router-link',{staticClass:"btn btn-outline-primary link-edit",attrs:{"to":{
                  name: 'clients_show',
                  params: { id: _vm.client.id }
                }}},[_vm._v("Voltar")])],1)])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }