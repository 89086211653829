<template>
  <div class="col-12">
    <Header />
    <Form />
  </div>
</template>

<script>
import Header from "../Components/Header.vue";
import Form from "../Components/Form.vue";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    Header,
    Form,
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions("clients", ["ActionSetClientToUpdate", "ActionShow"]),
  },

  computed: {
    ...mapState("clients", ["client"]),
  },

  async created() {
    await this.ActionSetClientToUpdate(null);
    await this.ActionShow();
    await this.ActionSetClientToUpdate(this.client);
  },
};
</script>

<style>
</style>
