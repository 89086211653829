<template>
  <div class="p-4 col-12">
    <b-form-group id="input-group-1" label="Prefeitura" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="clientToUpdate.config.login_text"
        class="form-group--error"
        :state="validateState('login_text')"
      >
      </b-form-input>
      <b-form-invalid-feedback>Campo obrigatório.</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="input-group-2" label="Prefeito" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="clientToUpdate.config.login_subtext"
        :state="validateState('login_subtext')"
      >
      </b-form-input>
      <b-form-invalid-feedback>Não pode ser vazio.</b-form-invalid-feedback>
    </b-form-group>

    <div class="row">
      <div class="col-6">
        <b-form-group label="Imagem de Fundo">
          <label for="login_bg_image_model">
            <b-img :src="login_bg_image" thumbnail fluid class="w-100" />
          </label>

          <b-form-file
            id="login_bg_image_model"
            v-model="login_bg_image_model"
            accept="image/*"
            placeholder="Arraste e solte um arquivo..."
            drop-placeholder="Arquivo..."
          ></b-form-file>
        </b-form-group>
      </div>

      <div class="col-6">
        <b-form-group label="Imagem Logo">
          <label for="login_logo_image_model">
            <b-img :src="login_logo_image" thumbnail fluid class="w-100" />
          </label>

          <b-form-file
            id="login_logo_image_model"
            v-model="login_logo_image_model"
            accept="image/*"
            placeholder="Arraste e solte um arquivo..."
            drop-placeholder="Arquivo..."
          ></b-form-file>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  mixins: [validationMixin],

  data: () => ({
    login_logo_image_model: null,
    login_logo_image: null,
    login_bg_image_model: null,
    login_bg_image: null,
  }),

  validations: {
    clientToUpdate: {
      config: {
        login_text: {
          required,
          minLength: minLength(3),
        },
        login_subtext: {
          required,
          minLength: minLength(3),
        },
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.clientToUpdate.config[name];
      return $dirty ? !$error : null;
    },
    isValid() {
      this.$v.clientToUpdate.$touch();
      return !this.$v.clientToUpdate.$anyError;
    },
  },

  computed: {
    ...mapState("clients", ["clientToUpdate"]),
  },
  watch: {
    login_bg_image_model: function (value) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.login_bg_image = e.target.result;
      };
      fileReader.readAsDataURL(value);
      this.clientToUpdate.config.login_bg_image = value;
    },

    login_logo_image_model: function (value) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.login_logo_image = e.target.result;
      };
      fileReader.readAsDataURL(value);
      this.clientToUpdate.config.login_logo_image = value;
    },
  },
  created() {
    this.login_logo_image = this.clientToUpdate.config.login_logo_image;
    this.login_bg_image = this.clientToUpdate.config.login_bg_image;
  },
};
</script>

<style>
</style>
